import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            light: {
                primary: "#4caf50",
                secondary: "#8bc34a",
                accent: "#cddc39",
                error: "#ffeb3b",
                warning: "#ffc107",
                info: "#ff5722",
                success: "#795548",
            },
            dark: {
                primary: colors.purple.accent4,
                secondary: "#8bc34a",
                accent: "#cddc39",
                error: "#ffeb3b",
                warning: "#ffc107",
                info: "#ff5722",
                success: "#795548",
            },
        },
    }
});
