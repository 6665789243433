<template>
  <div>
    <NoticeHeader :noticeHeight="noticeHeight" :imageSrc="imageSrc" />
    <HeaderBar :noticeHeight="noticeHeight" />
    <v-container v-bind:class="{ scrollMain: scrollMain }">
      <v-row class="d-flex flex-row mb-6">
        <v-col sm="6" cols="12" class="card-text">
          <h1>Early Accsess</h1>
          <p>
            I'm going to open early accsess program on Kickstarter. Please zazen and wait patiently.
          </p>
          <router-link to="/feature">
            <v-btn color="primary" style="text-transform: none" disabled>
              Coming Soon!!
            </v-btn>
          </router-link>
        </v-col>
        <v-col sm="6" cols="12">
          <v-img src="../assets/images/zazen.png"></v-img>
        </v-col>
      </v-row>

      <v-row class="d-flex flex-row-reverse mb-6">
        <v-col sm="6" cols="12" class="card-text">
          <h1>System Requirements</h1>
          <h3>PC</h3>
          <ul>
            <li>Windows, Mac, Chromebook, Linux</li>
            <li>
              Google Chrome, Mozilla Firefox, Safari, Microsoft Edge, Microsoft
              Microsoft Internet Explorer version 11 or later
            </li>
          </ul>
          <h3>Others</h3>
          <ul>
            <li>Chrome or Safari on an iPad (iOS 11 or later)</li>
            <li>Android (Android 8 or later)</li>
            <li>Microsoft Surface Pro (Windows 10) tablet</li>
          </ul>
        </v-col>
        <v-col sm="6" cols="12">
          <v-img src="../assets/images/requirement.png"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import NoticeHeader from "../components/NoticeHeader.vue";
import HeaderBar from "../components/HeaderBar.vue";


export default {
  props: ["noticeHeight", "scrollMain"],
  components: { NoticeHeader, HeaderBar },
  data() {
    return {
      imageSrc: require("../assets/images/seekingkindness.jpg"),
    };
  },
};
</script>

<style scoped>
</style>