<template>
  <div>
    <NoticeHeader :noticeHeight="noticeHeight" :imageSrc="imageSrc" />
    <HeaderBar :noticeHeight="noticeHeight" />
    <v-container v-bind:class="{ scrollMain: scrollMain }">
      <v-row class="d-flex flex-row mb-6">
        <v-col sm="6" cols="12" class="card-text">
          <h4>Video editing with interactive AI</h4>
          <h1>Genetic Modification</h1>
          <p>
            XParams is software that creates new value by Virtual Genetic
            Modification for humans in videos. Users can edit videos freely and
            intuitively through our AI.
          </p>
          <router-link to="/about">
            <v-btn color="primary" style="text-transform: none">
              What is XParams.
            </v-btn>
          </router-link>
        </v-col>
        <v-col sm="6" cols="12">
          <v-img src="../assets/images/carlos.png"></v-img>
        </v-col>
      </v-row>

      <v-row class="d-flex flex-row-reverse mb-6">
        <v-col sm="6" cols="12" class="card-text">
          <h4>Unique algorithm</h4>
          <h1>Beyond DeepFake</h1>
          <p>
            It is not software that replaces the face with AI like DeepFake. Our
            software algorithm that goes beyond DeepFake to recreate the ideal
            actor.
          </p>
          <router-link to="/feature">
            <v-btn color="primary" style="text-transform: none">
              Compare with Deepfake
            </v-btn>
          </router-link>
        </v-col>
        <v-col sm="6" cols="12">
          <v-img src="../assets/images/fakepresident.png"></v-img>
        </v-col>
      </v-row>

      <v-row class="d-flex flex-row mb-6">
        <v-col sm="6" cols="12" class="card-text">
          <h4>Optimized environment</h4>
          <h1>Powerful & Easy</h1>
          <p>
            XParams provides a powerful editing environment using the cloud as
            well as excellent AI. You can quickly access a fast and stable video
            editing environment.
          </p>
          <router-link to="/buynow">
            <v-btn color="primary" style="text-transform: none">
              Buy Now
            </v-btn>
          </router-link>
        </v-col>
        <v-col sm="6" cols="12">
          <v-img src="../assets/images/powerfuleasy.png"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import NoticeHeader from "../components/NoticeHeader.vue";
import HeaderBar from "../components/HeaderBar.vue";

export default {
  components: { HeaderBar, NoticeHeader },
  props: ["noticeHeight", "scrollMain"],
  data() {
    return {
      imageSrc: require("../assets/images/sliceface.png"),
      // "https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs/225904666/original/1114c9a6be1fab0f41934bdc889874a5640418a7/make-cyberpunk-anime-style-illustration-fo-your.jpg",
    };
  },
};
</script>

<style scoped>
</style>