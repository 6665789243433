<template>
  <div>
    <NoticeHeader :noticeHeight="noticeHeight" :imageSrc="imageSrc" />
    <HeaderBar :noticeHeight="noticeHeight" />
    <v-container v-bind:class="{ scrollMain: scrollMain }">
      <v-row>
        <v-col cols="12">
          <v-card class="card-text">
            <h1>Features of XParams</h1>
            <p>
              XParams enables real-time editing using powerful AI even on
              low-end PCs. Also, unlike Deepfake, it does not require AI
              preparation or design software expertise. New software
              specializing in intuitive video editing.
            </p>
            <v-data-table
              :headers="headers"
              :items="comparisons"
              :hide-default-footer="true"
              disable-sort
              mobile-breakpoint="0"
            >
              <template v-slot:[`item.photoshop`]="{ item }">
                <v-icon :color="item.photoshop.color">{{
                  item.photoshop.icon
                }}</v-icon>
              </template>
              <template v-slot:[`item.deepfake`]="{ item }">
                <v-icon :color="item.deepfake.color">{{
                  item.deepfake.icon
                }}</v-icon>
              </template>
              <template v-slot:[`item.xparams`]="{ item }">
                <v-icon :color="item.xparams.color">{{
                  item.xparams.icon
                }}</v-icon>
              </template>
              <template v-slot:[`item.model3d`]="{ item }">
                <v-icon :color="item.model3d.color">{{
                  item.model3d.icon
                }}</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-row mb-6">
        <v-col sm="6" cols="12" class="card-text">
          <h1>Easy Adjustment</h1>
          <p>
            With XParams, you can interactively adjust the elements of the
            actor's face. All you need to do with XParams is a simple mouse
            operation.
          </p>
        </v-col>
        <v-col sm="6" cols="12">
          <video
            poster="../assets/images/carlos.png"
            loop
            autoplay
            playsinline
            class="video_card"
          >
            <source src="../assets/demos/demo.mp4" type="video/mp4" />
            <p>Something wrong...</p>
          </video>
        </v-col>
      </v-row>

      <v-row class="d-flex flex-row-reverse mb-6">
        <v-col sm="6" cols="12" class="card-text">
          <h1>Face Swap</h1>
          <p>
            XParams was developed for the purpose of adjusting the face by
            parameters. However, if you still want Face Swap, it's a good idea
            to adjust the parameters to get the look you want. Of course, you
            don't have to collect teacher data like Deepfake. (Functions that
            support Face Swap will be developed in the future.)
          </p>
        </v-col>
        <v-col sm="6" cols="12">
          <v-img src="../assets/images/swapface.jpg"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import NoticeHeader from "../components/NoticeHeader.vue";
import HeaderBar from "../components/HeaderBar.vue";

const pro = { icon: "mdi-check-circle", color: "green" };
const norm = { icon: "", color: "grey" };
const con = { icon: "mdi-close-circle", color: "red" };

export default {
  props: ["noticeHeight", "scrollMain"],
  components: { NoticeHeader, HeaderBar },
  data() {
    return {
      imageSrc: require("../assets/images/madara.jpg"),
      headers: [
        {
          text: "hoge",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "XParams", value: "xparams" },
        { text: "Deepfake", value: "deepfake" },
        { text: "PhotoShop", value: "photoshop" },
        { text: "3D-Model", value: "model3d" },
      ],
      comparisons: [
        {
          name: "Adjustment",
          photoshop: pro,
          deepfake: con,
          xparams: pro,
          model3d: pro,
        },
        {
          name: "No Expertise",
          photoshop: con,
          deepfake: norm,
          xparams: pro,
          model3d: con,
        },
        {
          name: "Swap Face",
          photoshop: pro,
          deepfake: pro,
          xparams: norm,
          model3d: norm,
        },
        {
          name: "Realtime Edit",
          photoshop: pro,
          deepfake: con,
          xparams: pro,
          model3d: pro,
        },
        {
          name: "Create Video",
          photoshop: con,
          deepfake: pro,
          xparams: pro,
          model3d: pro,
        },
        {
          name: "Low-End PC",
          photoshop: norm,
          deepfake: con,
          xparams: pro,
          model3d: con,
        },
      ],
    };
  },
};
</script>

<style scoped>
.video_card {
  position: relative;
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  padding-right: 2%;
}
</style>