<template>
  <v-app-bar :height="noticeHeight">
    <div id="notice_board">
      <a>
        <p id="notice_message">
          2022/xx/xx Please purchase a membership on Kickstarter!
        </p>
      </a>
      <v-img :src="imageSrc" :height="imgHeight" :gradient="gradient"></v-img>
    </div>
  </v-app-bar>
</template>

<script>
import anime from "animejs";
const textLength = 4000;
const duration = 5 * textLength;

export default {
  props: ["noticeHeight", "imageSrc"],
  data() {
    return {
      imgHeight: this.noticeHeight - 50,
      gradient: "to bottom, rgba(0,0,0,0), rgba(0,0,0,0)",
    };
  },
  beforeUpdate() {},
  mounted() {
    anime({
      targets: "#notice_message",
      translateX: -textLength,
      duration: duration,
      loop: true,
      easing: "linear",
    });
    window.addEventListener("scroll", this.watchScroll);
  },
  methods: {
    watchScroll() {
      const x = window.scrollY / window.innerHeight;
      const alpha = - x * (x - 1.7);
      this.gradient = `to bottom, rgba(0,0,0,${alpha}), rgba(0,0,0,${alpha})`;
    },
  },
};
</script>

<style lang="scss">
#notice_board {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#notice_message {
  font-family: "KarnivoreDigit";
  font-size: 48px;
  font-weight: 100;
  white-space: nowrap;
  pointer-events: none;
  margin-left: 100%;
  margin-top: 0%;
  margin-bottom: 0%;
}
</style>