<template>
  <v-toolbar
    dark
    color="grey darken-4"
    v-bind:class="{ 'fix-header': isFixed }"
  >
    <v-menu offset-y rounded="rounded">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="primary" small>
          <v-icon dark> mdi-menu </v-icon>
        </v-btn>
      </template>

      <v-list v-for="link in links" :key="link.path">
        <v-list-item link :to="link.path">
          {{ link.text }}
        </v-list-item>
      </v-list>
    </v-menu>

    <v-spacer></v-spacer>

    <router-link to="/" style="text-decoration: none">
      <p id="logo_name">xparams</p>
    </router-link>
  </v-toolbar>
</template>


<script>
export default {
  props: ["noticeHeight"],
  data() {
    return {
      drawer: false,
      isFixed: false,
      links: [
        { path: "/login", text: "Login" },
        { path: "/", text: "Home" },
        { path: "/about", text: "About" },
        { path: "/feature", text: "Feature" },
        { path: "/buynow", text: "BuyNow" },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > this.noticeHeight) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
  },
};
</script>

<style lang="scss">
#logo_name {
  font-family: "KarnivoreDigit";
  font-size: 40px;
  font-weight: 100;
}

.fix-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
</style>
