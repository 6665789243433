<template>
  <div>
    <NoticeHeader :noticeHeight="noticeHeight" :imageSrc="imageSrc" />
    <HeaderBar :noticeHeight="noticeHeight" />
    <v-container v-bind:class="{ scrollMain: scrollMain }">
      <v-row class="d-flex flex-row mb-6">
        <v-col sm="6" cols="12" class="card-text">
          <h1>Big-timers</h1>
          <p>
            The impression of the performers is the key to brushing up the
            video. XParams enables top-notch casts with powerful and intuitive
            AI. Interactive video editing is possible by manipulating the
            parameterized video. You can quickly start video editing in the
            optimal cloud environment provided by XParams.
          </p>
        </v-col>
        <v-col sm="6" cols="12">
          <v-img src="../assets/images/kiss_scene.jpg"></v-img>
        </v-col>
      </v-row>

      <v-row class="d-flex flex-row-reverse mb-6">
        <v-col sm="6" cols="12" class="card-text">
          <h1>Number rules the universe</h1>
          <p>
            As Pythagoras said, "Number rules the universe," Number rules
            videos. By manipulating numbers (parameters), you can create videos
            that are free from all bindings. Is the work you envisioned a sequel
            to Statham's Avatar? Or is it a Shrek diet story?
          </p>
        </v-col>
        <v-col sm="6" cols="12">
          <v-img src="../assets/images/numbervideo.png"></v-img>
        </v-col>
      </v-row>

      <v-row class="d-flex flex-row mb-6">
        <v-col sm="6" cols="12" class="card-text">
          <h1>Trying is believing.</h1>
          <h3>Don't just see, try it.</h3>
        </v-col>
        <v-col sm="6" cols="12">
          <Demo />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import NoticeHeader from "../components/NoticeHeader.vue";
import HeaderBar from "../components/HeaderBar.vue";
import Demo from "../components/Demo.vue";

export default {
  props: ["noticeHeight", "scrollMain"],
  components: { NoticeHeader, HeaderBar, Demo },
  data() {
    return {
      imageSrc: require("../assets/images/matgone.png"),
    };
  },
};
</script>

<style scoped>
</style>