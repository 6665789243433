<template>
  <v-app>
    <v-main>
      <router-view :scrollMain="scrollMain" :noticeHeight="noticeHeight" />
    </v-main>
    <FooterBar />
  </v-app>
</template>


<script>
import FooterBar from "./components/FooterBar.vue";

export default {
  components: { FooterBar },
  data() {
    return {
      scrollMain: false,
      noticeHeight: window.innerHeight - 72,
    };
  },
  mounted() {
    this.scrollMain = false;
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > this.noticeHeight) {
        this.scrollMain = true;
      } else {
        this.scrollMain = false;
      }
    },
  },
};
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.scrollMain {
  padding-top: 72px;
}

.card-text {
  color: whitesmoke;
  line-height: 2;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3em;
}
</style>
