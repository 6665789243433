<template>
  <v-container>
    <v-layout justify-center>
      <v-img max-height="192" max-width="192" :src="tunedImage"></v-img>
    </v-layout>
    <v-slider
      v-model="faceParams.noseHead"
      max="19"
      min="0"
      label="Eyebrow"
      thumb-label="always"
    ></v-slider>
  </v-container>
</template>

<script>
var tunedImages = [];
for (var i = 0; i < 20; i++) {
  tunedImages.push(
    require(`../assets/demos/images/${("00" + i).slice(-2)}.jpg`)
  );
}
export default {
  data() {
    return {
      faceParams: { noseHead: 10 },
      tunedImage: tunedImages[10],
    };
  },
  watch: {
    faceParams: {
      handler() {
        this.tunedImage = tunedImages[this.faceParams.noseHead];
      },
      deep: true,
    },
  },
  methods: {},
};
</script>
