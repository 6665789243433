var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('NoticeHeader',{attrs:{"noticeHeight":_vm.noticeHeight,"imageSrc":_vm.imageSrc}}),_c('HeaderBar',{attrs:{"noticeHeight":_vm.noticeHeight}}),_c('v-container',{class:{ scrollMain: _vm.scrollMain }},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card-text"},[_c('h1',[_vm._v("Features of XParams")]),_c('p',[_vm._v(" XParams enables real-time editing using powerful AI even on low-end PCs. Also, unlike Deepfake, it does not require AI preparation or design software expertise. New software specializing in intuitive video editing. ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.comparisons,"hide-default-footer":true,"disable-sort":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.photoshop",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.photoshop.color}},[_vm._v(_vm._s(item.photoshop.icon))])]}},{key:"item.deepfake",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.deepfake.color}},[_vm._v(_vm._s(item.deepfake.icon))])]}},{key:"item.xparams",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.xparams.color}},[_vm._v(_vm._s(item.xparams.icon))])]}},{key:"item.model3d",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.model3d.color}},[_vm._v(_vm._s(item.model3d.icon))])]}}],null,true)})],1)],1)],1),_c('v-row',{staticClass:"d-flex flex-row mb-6"},[_c('v-col',{staticClass:"card-text",attrs:{"sm":"6","cols":"12"}},[_c('h1',[_vm._v("Easy Adjustment")]),_c('p',[_vm._v(" With XParams, you can interactively adjust the elements of the actor's face. All you need to do with XParams is a simple mouse operation. ")])]),_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('video',{staticClass:"video_card",attrs:{"poster":require("../assets/images/carlos.png"),"loop":"","autoplay":"","playsinline":""}},[_c('source',{attrs:{"src":require("../assets/demos/demo.mp4"),"type":"video/mp4"}}),_c('p',[_vm._v("Something wrong...")])])])],1),_c('v-row',{staticClass:"d-flex flex-row-reverse mb-6"},[_c('v-col',{staticClass:"card-text",attrs:{"sm":"6","cols":"12"}},[_c('h1',[_vm._v("Face Swap")]),_c('p',[_vm._v(" XParams was developed for the purpose of adjusting the face by parameters. However, if you still want Face Swap, it's a good idea to adjust the parameters to get the look you want. Of course, you don't have to collect teacher data like Deepfake. (Functions that support Face Swap will be developed in the future.) ")])]),_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('v-img',{attrs:{"src":require("../assets/images/swapface.jpg")}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }