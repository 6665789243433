<template>
  <v-footer>
    <v-container>
      <h3 align="center">This page is also parameters.</h3>
      <v-row class="py-2">
        <v-btn href="https://www.youtube.com/" small>
          <v-icon color="red"> mdi-youtube </v-icon>
        </v-btn>
        <v-slider v-model="tunableColor.red" max="255" min="0" color="red"></v-slider
      ></v-row>

      <v-row class="py-2">
        <v-btn href="https://www.kickstarter.com/" small>
          <v-icon color="green"> mdi-kickstarter </v-icon>
        </v-btn>
        <v-slider v-model="tunableColor.green" max="255" min="0" color="green"></v-slider
      ></v-row>

      <v-row class="py-2">
        <v-btn href="https://www.twitter.com/" small>
          <v-icon color="blue"> mdi-twitter </v-icon>
        </v-btn>
        <v-slider v-model="tunableColor.blue" max="255" min="0" color="blue"></v-slider
      ></v-row>
    </v-container>
  </v-footer>
</template>


<script>
function ColorToHex(color) {
  var hexadecimal = color.toString(16);
  return hexadecimal.length == 1 ? "0" + hexadecimal : hexadecimal;
}
function ConvertRGBtoHex(color) {
  return (
    "#" +
    ColorToHex(color.red) +
    ColorToHex(color.green) +
    ColorToHex(color.blue)
  );
}

export default {
  data() {
    return {
      tunableColor: { red: 170, green: 0, blue: 255 },
    };
  },
  watch: {
    tunableColor: {
      handler() {
        this.$vuetify.theme.themes.dark.primary = ConvertRGBtoHex(
          this.tunableColor
        );
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.v-messages {
  min-height: 0px !important;
}
</style>
