import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueGtag from 'vue-gtag'
import router from './router'

Vue.config.productionTip = false

if (process.env.NODE_ENV == "production") {
  Vue.use(VueGtag, {
    config: {
      id: process.env.VUE_APP_GTAG_ID,
      params: {
        send_page_view: true
      }
    }
  }, router);
} else {
  Vue.use(router);
}

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
