<template>
  <div>
    <!-- <NoticeHeader :noticeHeight="noticeHeight" :imageSrc="imageSrc" /> -->
    <HeaderBar :noticeHeight="noticeHeight" />
    <h1>Not Found</h1>
  </div>
</template>


<script>
// import NoticeHeader from "../components/NoticeHeader.vue";
import HeaderBar from "../components/HeaderBar.vue";

export default {
  props: ["noticeHeight", "scrollMain"],
  components: { HeaderBar },
  data() {
    return {
      imageSrc:
        "https://nagaragawa.org/wp_nagaragawa/wp-content/themes/nagaragawa/images/img_stay02_@2x.jpg",
    };
  },
};
</script>

<style scoped>
</style>